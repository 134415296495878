<template>
  <div class="teacher">
    <list-template
        :loading="loading"
        :current-page="page"
        :table-config="tableConfig"
        :search-config='searchConfig'
        :table-data="tableData"
        :total="total"
        @onChangePage="changeCurrentPage"
        @onReset="search = null"
        @onSearch='onSearch'
        @onExportAll="onDownload">
      <div slot='title'>
      </div>
    </list-template>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  _config:{
    route:{
      path:"list",
      meta:{"title":"列表","keepAlive":true},

    }
  },
  data() {
    return {
      loading: true,
      // 表格搜索配置
      searchConfig: [{
        prop: 'user_name',
        placeholder: '搜索教师姓名',
      }, {
        prop: 'start_date',
        tag: 'datePicker',
        property: {
          size: 'small',
          style: 'margin-right:20rem;',
          valueFormat: 'yyyyMM',
          type: 'monthrange',
          rangeSeparator: '至',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
        },
      }, {
        prop: 'school_id',
        tag: 'select',
        placeholder: '筛选校区',
        options: []
      }, {
        prop: 'grade_id',
        tag: 'select',
        placeholder: '筛选年级',
        options: [],
        label: 'grade_name',
        value: 'id'
      }, {
        prop: 'subject_id',
        tag: 'select',
        placeholder: '筛选学科',
        options: []
      }],
      // 表格配置
      tableConfig: [
        {prop: 'user_name', label: '教师姓名', width: 100, fixed: 'left'},
        {prop: 'date', label: '统计月份', width: 120, fixed: 'left'},
        {prop: 'subject_name', label: '学科', fixed: 'left'},
        {prop: 'grade', label: '年级', width: 140},
        {
          label: '学科课时统计',
          children: [
            {prop: 'all_calendar_num', label: '学科课时'},
            {prop: 'late_attendance_num', label: '迟到课时'},
            {prop: 'defect_attendance_num', label: '缺勤课时'},
            {prop: 'point_calendar_num', label: '靶向班'}
          ]
        },
        {
          label: '自习统计',
          children: [
            {prop: 'morning_self_study_num', label: '早读'},
            {prop: 'self_study_num', label: '白天自习'},
            {prop: 'evening_self_study_type_2', label: '教学班晚自习'},
            {prop: 'evening_self_study_type_1', label: '行政班晚自习'},
            {prop: 'self_late_num', label: '迟到课时'},
            {prop: 'self_defect_num', label: '缺勤课时'},
          ]
        },
        {
          label: '代课课时',
          children: [
            {prop: 'agent_same', label: '同科目(正课)'},
            {prop: 'agent_not_same', label: '不同科目(正课)'},
            {prop: 'agent_mssn', label: '早读'},
            {prop: 'agent_ssn', label: '白天自习'},
            {prop: 'agent_essn_2', label: '教学班晚自习'},
            {prop: 'agent_essn_1', label: '行政班晚自习'},
            {prop: 'agent_pcn', label: '靶向班'},
            {prop: 'agent_late_num', label: '迟到课时'},
            {prop: 'agent_defect_num', label: '缺勤课时'},
          ]
        },
        {
          label: '行课课时',
          children: [
            {prop: 'administrative_class_week_exam', label: '周考'},
            {prop: 'administrative_class_society', label: '社团课'},
            {prop: 'administrative_class_late_num', label: '迟到课时'},
            {prop: 'administrative_class_defect_num', label: '缺勤课时'},
          ]
        },
        {prop: "school_name", label: "校区", "width": 150, fixed: 'right'}
      ],
      search: {},
      total: 0,
      // 表格中的数据
      tableData: [],
    }
  },
  computed: {
    ...mapState(["page"])
  },
  mounted() {
    this.$store.commit("setPage", 1);
    this.getData()
  },
  activated() {
    this.getData()
  },
  methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit("setPage", e);
      this.getData()
    },
    /**
     * 获取数据
     */
    getData() {
      let {search, page} = this
      let searchConfig = JSON.parse(JSON.stringify(search))
      if (JSON.stringify(searchConfig) !== '{}') {
        if (searchConfig.start_date) {
          searchConfig.start_date = search.start_date[0].replace(/(\d{4})(\d{2})/, '$1-$2')
          searchConfig.end_date = search.start_date[1].replace(/(\d{4})(\d{2})/, '$1-$2')
        }
      }
      this.loading = true;
      this.$_axios2.get('api/calendar-statistics/teacher/class-hour', {params: {...searchConfig, page}}).then(res => {
        let {data} = res.data
        this.tableData = data.list
        this.total = data.page.total
      }).finally(() => this.loading = false);
    },
    // 搜索按钮被点击
    onSearch(val) {
      this.$store.commit("setPage", 1);
      this.search = val
      this.getData()
    },
    onDownload() {
      let {search} = this
      let searchConfig = JSON.parse(JSON.stringify(search))
      if (JSON.stringify(searchConfig) !== '{}') {
        if (searchConfig.start_date) {
          searchConfig.start_date = search.start_date[0].replace(/(\d{4})(\d{2})/, '$1-$2')
          searchConfig.end_date = search.start_date[1].replace(/(\d{4})(\d{2})/, '$1-$2')
        }
      }
      this.$_axios2.post('api/calendar-statistics/teacher/class-hour-export', {...searchConfig}).then(res => {
        this.$tools.download('教师课时统计', res.data)
      })
    }
  },
  created() {
    for (let schoolArrKey in JSON.parse(sessionStorage.getItem('USER_INFO')).school_arr) {
      this.searchConfig[2].options.push({
        value: schoolArrKey,
        label: JSON.parse(sessionStorage.getItem('USER_INFO')).school_arr[schoolArrKey]
      })
    }
    this.$_axios2.get('api/common/screen/grade').then(res => {
      let {data} = res.data
      this.searchConfig[3].options = data
    })
    this.$_axios2.get('api/pre-course-select/options/subjects-l1').then(res => {
      let {data} = res.data
      this.searchConfig[4].options = data
    })
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-range-separator {
  width: 24rem !important;
}
</style>
